import moment from 'moment'
const states = require('../assets/config/states.json')

export const state = () => ({
  info: {},
  sitemap: [],
  entries: {},
  finding: false,
  searched: false,
  location: false,
  locations: [],
  zipcode: null,
  mainHeight: 0,
  loggedIn: null,
  account: null,
  accessToken: null,
  clientId: null,
  bookDate: null,
  bookFlows: {},
  bookFlow: null,
  routes: {
    page: {},
    eventType: {},
    event: {},
    special: {},
    activity: {},
    center: {},
    bookingFlow: {},
    article: {}
  },
  articles: {},
  centerData: {},
  deliveryCenter: null,
  highContrast: false,
  languageToggle: false,
  luckModal: true,
  foodModal: {
    toggled: false,
    content: {}
  },
  locationFinder: true,
  seasonPass: null,
})

export const mutations = {
  setInfo(state, info) {
    state.info = info
  },

  setSitemap(state, sitemap) {
    state.sitemap = sitemap
  },

  setEntries(state, entries) {
    state.entries = entries
  },

  finding(state, bool) {
    state.finding = bool
  },

  searched(state, bool) {
    state.searched = bool
  },

  setLocation(state, loc, store = true) {
    state.location = loc
    if (store && localStorage && loc != null) {
      localStorage.setItem('me_location', JSON.stringify(loc))
    }
  },

  setLocations(state, locs) {
    state.locations = locs
  },

  setZipcode(state, zip) {
    state.zipcode = zip
    if (localStorage) {
      localStorage.setItem('me_zipcode', zip)
    }
  },

  setMainHeight(state, height) {
    state.mainHeight = height
  },

  setLogInState(state, loggedIn) {
    state.loggedIn = loggedIn
  },

  setAccount(state, obj) {
    state.account = obj
    if (localStorage) {
      localStorage.setItem('me_account', JSON.stringify(obj))
    }
  },

  unsetAccount(state) {
    state.account = null
    if (localStorage) {
      localStorage.removeItem('me_account')
    }
  },

  accessToken(state, token) {
    state.accessToken = token
  },

  clientId(state, id) {
    state.clientId = id
  },

  setBookDate(state, date) {
    state.bookDate = date
  },

  setFlows(state, flows) {
    state.bookFlows = flows
  },

  setFlow(state, flow) {
    state.bookFlow = flow
  },

  setRoute(state, obj) {
    state.routes[obj.type][obj.name] = obj.data
  },

  setArticles(state, obj) {
    state.articles[obj.type] = obj.data
  },

  setCenterData(state, obj) {
    state.centerData = obj
  },

  setDeliveryCenter(state, center) {
    state.deliveryCenter = center
  },

  setHighContrast(state, bool) {
    state.highContrast = bool
    if (bool) {
      localStorage.setItem('me_contrast', 'true')
    } else {
      localStorage.removeItem('me_contrast')
    }
  },

  setLanguageToggle(state, bool) {
    state.languageToggle = bool
    if (bool) {
      localStorage.setItem('me_lang', 'spanish')
    } else {
      localStorage.removeItem('me_lang')
    }
  },

  setLuckModal(state, bool) {
    state.luckModal = bool
  },
  setFoodModal(state, payload) {
    state.foodModal = {
      toggled: payload.toggled,
      content: payload.content,
    }
  },
  setLocationFinder(state, bool) {
    state.locationFinder = bool
  },

  setSeasonPass(state, arr) {
    state.seasonPass = arr
  },
}

export const actions = {
  async nuxtServerInit({
    commit
  }, context) {
    if (process.env.NODE_ENV == 'development') {
      let sitemap = await context.$storage.buildSitemap()
      commit('setSitemap', sitemap.sitemap)
      commit('setEntries', sitemap.entries)
    } else {
      commit('setSitemap', await context.$storage.getFile('sitemap'))
    }
    commit('setInfo', {
      title: process.env.npm_package_title,
      menus: {
        main: await context.$contentful.getEntryById('5JEI4xirsj2VKVGSvQHgvs'),
        careers: await context.$contentful.getEntryById('6JCgsPMWKfuRDCSEi6BfaM'),
        footer: await context.$contentful.getEntryById('1kHZic25T832ocMOdJOaHU'),
        social: await context.$contentful.getEntryById('6Va4CRQ6hWmVpkwWReLPar'),
        socialAlt: await context.$contentful.getEntryById('5yohzuBcym3dZDyycZEmOi'),
        legal: await context.$contentful.getEntryById('NvMEI5lPN9V6I20TKrRJW')
      },
      copyright: `©${moment().year()} ${process.env.npm_package_author_name}`
    })
    // store center data for location search
    let centers = await context.$contentful.getEntries('center')
    let locationData = {}
    centers.map(e => {
      if (e.fields.locationData) {
        locationData[e.fields.locationData.centerId.toUpperCase()] = {
          storefrontImage: e.fields.storefrontImage,
          slug: e.fields.slug,
          path: `${e.fields.state}/${e.fields.slug}/`,
          state: e.fields.locationData.state,
          city: e.fields.locationData.city,
          name: e.fields.locationData.centerName.trim(),
          hours: e.fields.hours,
          addHours: e.fields.locationData.additionalHoursText,
          venues: e.fields.venues,
          rentals: e.fields.rentals,
          openDate: e.fields.locationData.openDate
        }
      }
    })
    commit('setCenterData', locationData)
    let bookFlows = await context.$contentful.getEntries('bookingFlow')
    let flowObj = {}
    bookFlows.map(b => {
      flowObj[b.fields.slug] = b
    })
    commit('setFlows', flowObj)
  },
  nuxtClientInit({
    commit
  }, context) {
    if (localStorage.getItem('me_location')) {
      commit('setLocation', JSON.parse(localStorage.getItem('me_location')))
    }
    if (localStorage.getItem('me_account')) {
      commit('setAccount', JSON.parse(localStorage.getItem('me_account')))
    }
    if (localStorage.getItem('me_zipcode')) {
      commit('setZipcode', localStorage.getItem('me_zipcode'))
    }
    if (localStorage.getItem('me_cart')) {
      let cart = JSON.parse(localStorage.getItem('me_cart'))
      commit('setFlow', context.store.state.bookFlows[cart.type])
      commit('cart/cart', cart)
    }
    if (localStorage.getItem('me_contrast')) {
      commit('setHighContrast', true)
    }
    if (localStorage.getItem('me_lang')) {
      commit('setLanguageToggle', true)
    }
  }
}
